/* Loader Wrapper */
.loaderWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(259, 259, 259, 0.8);
  position: absolute;
  width: 100%;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 1401;
}

/* Pulsing Circle */
.pulsingCircle {
  width: 50px;
  height: 50px;
  background-color: var(--primary-color); /* Change to your preferred color */
  border-radius: 50%;
  animation: pulse 1.5s infinite ease-in-out;
}

/* Animation */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.6;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Loader Message */
.loaderMessage {
  margin-top: 1rem;
  font-size: 1rem;
  color: #333;
}
