.page_title {
  margin: 0px;
}
.headingContainer {
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--grey-light);
}
.searchFilterContainer {
  gap: 15px !important;
  flex-wrap: nowrap !important;
  justify-content: end;
  margin-top: 20px !important;
  margin-bottom: 20px;
}
.checkboxContainer {
  margin: 0px !important;
}
