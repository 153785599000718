.breadcrumbs {
  display: flex;
  align-items: center;
}
.breadcrumb_arrow {
  &:hover {
    background: none !important;
  }
}
a.breadcrumbs_list {
  color: var(--grey-50066718-c, #66718c);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  -webkit-text-decoration: none;
}

p.breadcrumbs_last {
  color: var(--grey-50066718-c, #494949);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  -webkit-text-decoration: none;
}

@media screen and (max-width: 768px) {
  .MuiBreadcrumbs-separator {
    margin: 0px !important;
  }
}
