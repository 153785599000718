.page_title {
  margin-top: 10px;
}

.changePassword {
  cursor: pointer !important;
  margin-top: 15px !important;
  color: #a1a1a1 !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-decoration: underline !important;
  -webkit-text-decoration: underline !important;
}
