.dashboard_container_empty {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  .dashboard_empty_content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .add_master_btn {
      width: 30%;
      display: flex;
      gap: 15px;
      align-items: center;
      justify-content: center;
    }
  }
  @media screen and (max-width: 768px) {
    .dashboard_empty_content {
      text-align: center;
      .add_master_btn {
        width: 100%;
      }
    }
  }
}

.datagrid_wrapper {
  flex: 1 0;
}

.datagrid_top_btns {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0px !important;
  gap: 15px;
}
.actionMenulist ul {
  display: flex;
  width: 155px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
}
