.searchFilterContainer {
  gap: 15px;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
}
.tabsContainerheader {
  margin-bottom: 24px !important;
}
.tagContainerinner {
  border-radius: 4px;
  border: 1px solid var(--grey-light, #d9dde8);
  background: #fff;
  padding: 24px;
  .tagContainerTop {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #f0f1f8;
  }
  .tagName h3 {
    color: #a4a4a4;
    font-weight: 600;
    margin: 0px;
    b {
      color: #494949;
      font-weight: 600;
    }
  }
  .list_container {
    justify-content: end;
    ul {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
      gap: 24px;
      justify-content: end;
      align-items: center;
      li {
        color: #494949;
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        position: relative;
        padding-right: 24px;
        .labelName {
          font-size: 14px;
          font-weight: 500;
          color: #a4a4a4;
          display: block;
          margin-bottom: 5px;
        }
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          background: #b5bcd3;
          width: 1px;
          height: 21px;
        }
        &:last-child {
          padding-right: 0px;
          &::after {
            display: none;
          }
        }
      }
    }
  }
  .tagContainerBottom {
    align-items: center;
    .field_container {
      gap: 15px;
    }
    .btn_container {
      justify-content: end;
    }
  }
}
