.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
}

.content {
  flex-grow: 1;
  margin: 0px; // You can adjust this margin
  padding: 0px;
  background-color: #fff;
  display: flex;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
}

.contentInner {
  flex-grow: 1;
  margin: 0px; // You can adjust this margin
  padding: 16px 38px 16px 38px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  margin-top: 70px;
  height: calc(100vh - 70px);
  &.loginPageClass {
    margin-top: 0px;
    height: 100vh;
    width: 100% !important;
    padding: 0px;
  }
  @media screen and (max-width: 768px) {
    padding: 16px;
    gap: 10px;
  }
}
.contentInner.drawerOpenClass {
  width: calc(100% - 240px);
}

.footer {
  text-align: center;
  padding: 10px;
  background-color: #2c3e50;
  color: #ecf0f1;
  border-top: 2px solid #34495e;

  p {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
  }
}
