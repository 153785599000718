.dashboard_container {
  height: 100%;
  width: 100%;
  display: flex;
  .dashboard_container_empty {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    .dashboard_empty_content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .add_master_btn {
        width: 30%;
      }
    }
  }
}
.empty_dashboard_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.top_container {
  justify-content: space-between;
  .name_container {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: start;
  }
  .btn_container {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: end;
  }
}

.complition_container {
  // margin: 20px 0px;
  padding: 24px;
  background: var(--primary-color-light);
  border-radius: var(--Spacing-Spacing-8px, 8px);
  align-items: center;
  @media screen and (max-width: 768px) {
    justify-content: space-between;
    padding: 12px;
  }
  .main_content {
    h2 {
      margin: 0px;
      line-height: 1.3;
      span {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        color: #66718c;
        margin-left: 15px;
      }
      @media screen and (max-width: 768px) {
        text-align: left;
        font-size: 20px;
        span {
          font-size: 14px;
        }
      }
    }
    h3 {
      margin-top: 0px;
      font-size: 20px;
      @media screen and (max-width: 768px) {
        font-size: 14px;
        margin-bottom: 0px;
      }
    }
  }
  .view_inventory_link {
    justify-content: end;
    display: flex;
    a {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      color: #000;
      text-decoration: none;
      -webkit-text-decoration: none;
      &:hover {
        color: var(--primary-color);
      }
    }
    @media screen and (max-width: 768px) {
      padding-right: 0;
      a {
        gap: 0px;
        font-size: 13px;
      }
    }
  }
}
.counter_container {
  @media screen and (max-width: 768px) {
    gap: 10px;
  }
  .counter_box {
    padding: 16px;
    border-radius: 4px;
    border: 1px solid var(--primary-color-light);
    background: var(--White, #fff);
    h4 {
      margin-top: 0px;
      margin-bottom: 12px;
      color: #66718c;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      span {
        margin-left: 15px;
        padding-left: 15px;
        border-left: 1px solid var(--grey);
        color: var(--grey, #40475b);
      }
      @media screen and (max-width: 768px) {
        font-size: 14px;
        span {
          margin-left: 10px;
          padding-left: 10px;
        }
      }
    }
  }
  .progressBarContainer {
    display: flex;
    align-items: center;
    position: relative;
    gap: 16px;
    p {
      color: var(--grey, #40475b);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
  }
}

.slider_container {
  padding: 16px;
  border-radius: 4px;
  border: 1px solid var(--primary-color-light);
  background: var(--White, #fff);
  width: 100%;
  h2 {
    font-weight: 500;
    text-align: center;
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }
}

.actionMenulist {
  ul {
    width: 100%;
    padding: 0px;
    border-radius: 4px;
    background: #fff;
    // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    li {
      // color: #494949;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding: 10px 15px;
      // border-bottom: 0.4px solid rgba(0, 0, 0, 0.3);
      width: 100%;
      white-space: normal;
      .menuName {
        width: calc(100% - 25px);
      }
    }
  }
}
