.datatableContainer {
  background: transparent;
  // height: 100%;
  height: calc(100vh - 400px);
  width: 100%;
  box-sizing: border-box;
  @media (orientation: landscape) {
    height: calc(100vh - 100px);
  }
}

.datatableContainer_table {
  //border: 0.3px solid var(--disabled-color, #b9b9b9);
}

.noRowsOverlay {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: flex-start !important;
  padding: 2rem 3rem !important;
}
.skeletonLoader {
  align-items: start !important;
  justify-content: flex-start !important;
}
.empty_img {
  width: 120px;
  @media screen and (max-width: 768px) {
    width: 60px;
  }
}
.empty_msg {
  color: #7e7f8a;
  text-align: center;
  //font-family: "Nunito Sans" !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 20px 0px !important;
  display: block;
}
