header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--white, #fff);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  max-height: 60px;
  padding: var(--spacing-05, 12px) 32px;
  flex: 1 0 0;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 999;
  @media screen and (max-width: 768px) {
    padding: var(--spacing-05, 12px) 16px;
  }
  .logoContainer {
    display: flex;
    align-items: center;
    .textLogo {
      text-decoration: none;
      -webkit-text-decoration: none;
      h3 {
        font-family: "Montserrat";
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 31px; /* 96.875% */
        color: var(--primary-color);
        @media screen and (max-width: 768px) {
          font-size: 24px;
        }
      }
    }
  }
  .rightOptions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    @media screen and (min-width: 768px) {
      width: calc(100% - 240px);
    }
    @media screen and (max-width: 768px) {
      justify-content: end;
    }
    .companyDropdown {
      .fieldWrapper {
        margin-bottom: 0px;
      }
    }
    .contactMenuButton {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }
    .iconMenuButton {
      color: #3f4bdb;
    }
    .avatarCircle {
      width: var(--Spacing-Spacing-32px, 32px);
      height: var(--Spacing-Spacing-32px, 32px);
      cursor: pointer;
      border: 2px solid var(--primary-color);
    }
    @media (max-width: 768px) {
      gap: 5px;
    }
  }

  .userProfile {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    padding: 12px var(--Spacing-Spacing-20px, 20px);
    padding-right: 0px;
    gap: 10px;
    border: 0px;
    color: var(--primary-color);
    &:hover {
      border: 0px;
      background: none;
    }
    .welcomeText {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      align-content: center;
      gap: 10px;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: row;
    .userProfile {
      margin-bottom: 0px;
      // .welcomeText {
      //   display: none;
      // }
      .avatarCircle {
        width: 24px;
        height: 24px;
        font-size: 12px !important;
        cursor: pointer;
      }
    }
  }
}

.headerDropMenu {
  ul {
    width: 100%;
    padding: 0px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    li {
      color: #494949;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding: 15px 20px;
      border-bottom: 0.4px solid rgba(0, 0, 0, 0.3);
    }
  }
}

.header_container {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  .notification_bar {
    background: var(--primary, #414ed5);
    // background: rgba(0, 0, 0, 0.20);
    padding: 5px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    color: #fff;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    a {
      color: #fff;
      font-weight: 500;
      text-decoration: underline;
      -webkit-text-decoration: underline;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
        -webkit-text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
