::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #c1c1c1;
}

input[type="password"]::-ms-reveal {
  display: none !important;
}
.content_container,
.comman_wrapper {
  width: 100%;
  height: 100%;
}
.tab_wrapper {
  height: calc(100vh - 270px);
}
h2 {
  color: var(--grey-dark, #40475b);
  font-family: "Montserrat";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
}
h3 {
  color: var(--grey-dark, #40475b);
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px; /* 129.167% */
  margin: 16px 0px;
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
}

h5 {
  color: var(--grey-dark, #40475b);
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 31px; /* 193.75% */
  margin-top: 0px;
}

.MuiDataGrid-columnHeaders {
  background-color: #d9dde8 !important; /* Header background */
  color: #494949 !important; /* Header text color */
}

.MuiDataGrid-columnSeparator {
  display: none !important; /* Remove column separators */
}

.fieldWrapper {
  margin-bottom: 0.5rem !important;
}
.login_right_content {
  .fieldWrapper {
    margin-bottom: 24px !important;
  }
}
.AddInventory_img {
  background: #d9dde8;
  border-radius: 8px;
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  padding-top: 25px !important;
  justify-content: center;
  display: flex;
}

.MuiDataGrid-container--top [role="row"] {
  background: #d9dde8 !important;
  color: #494949 !important;
}
.slick-arrow {
  top: 40px !important;
  color: #494949 !important;
  z-index: 999;
}
.slick-next {
  right: 0px !important;
}
.slick-prev {
  left: 0px !important;
}

.filter_container {
  width: 500px;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 4px !important;
  background: var(--grey-light, #fafafd) !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1) !important;
  ul {
    li {
      display: flex;
      padding: 12px 16px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      background: #fff !important;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    li.filter_heading {
      display: flex !important;
      align-items: center !important;
      justify-content: space-between !important;
      background: none !important;
      padding: 0px !important;
      opacity: 1 !important;
      width: 100% !important;
      h3 {
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        width: 100%;
        margin: 0px;
      }
    }
  }
}

.dropzone_container_outer {
  padding: 3rem;
}
.dropzone_container {
  background: #f0f1f8;
  border: 2px dashed #9eaac1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 0rem;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  .noAccessIcon {
    background: #b5bcd3;
    border-radius: 10px;
    padding: 10px 30px;
    margin-bottom: 30px;
  }
  h3 {
    span {
      color: #66718c;
      text-decoration: underline;
      text-decoration-color: #66718c;
    }
  }
  h6 {
    color: #596584;
    font-weight: 400;
  }
  .dropzoneText {
    position: absolute;
    margin: auto;
    bottom: 15px;
  }
}
.download_sample_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 3rem;
  h6 {
    margin-bottom: 30px;
  }
}

.fileUpload_container {
  height: 100%;
  h3 {
    color: var(--grey-dark);
    font-size: 18px;
    border-bottom: 0.5px solid #9eaac1;
  }
  .fileUpload_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--grey-light);
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 20px;
    .fileUpload_name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      h3 {
        border-bottom: 0px;
        margin: 0px;
      }
      .noAccessIcon {
        background: #b5bcd3;
        border-radius: 4px;
        padding: 8px;
      }
    }
    .fileUpload_fail {
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 5px;
      color: var(--red, #d32f2f);
    }
    .fileUpload_success {
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 5px;
    }
  }
  .fileUpload_error_title {
    background: var(--grey-light);
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 8px 15px;
    color: var(--grey);
    font-weight: 600;
    font-size: 14px;
  }
  .fileUpload_btn {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 15px;
    margin-top: 50px;
    button {
      width: auto !important;
    }
  }
  .error_table {
    th {
      background: var(--grey-light);
      line-height: 1;
    }
    td {
      padding: 10px 16px;
    }
  }
}

.accordion_container {
  width: 100%;
  border: 0px;
  border-radius: 0px !important;
  box-shadow: none !important;
  .MuiAccordionDetails-root {
    padding: 16px !important;
    padding-bottom: 0px !important;
    @media screen and (max-width: 768px) {
      padding: 10px !important;
      padding-bottom: 0px !important;
    }
  }
  .MuiAccordion-root {
    box-shadow: 0px 0px 0px -3px rgba(0, 0, 0, 0.2),
      0px 1px 1px -1px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }
  .first_accordion_heading,
  .second_accordion_heading {
    border-radius: 4px;
    background: var(--grey-light, #d9dde8);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    margin: 0px !important;
    margin-bottom: 16px !important;
  }
  .second_accordion_heading {
    background: #f0f1f8;
  }
  .inventory_tag_container {
    max-height: 500px;
    overflow: auto;
  }
  &.Mui-expanded {
    .first_accordion_heading,
    .second_accordion_heading {
      margin-bottom: 0px !important;
    }
  }
}

.session_dialog_container {
  padding: 24px !important;
  h3 {
    margin-bottom: 0px !important;
  }
}
.session_dialogbtn_container {
  padding: 24px !important;
  padding-top: 0px !important;
}
