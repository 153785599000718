.page_title {
  margin-top: 10px;
}
.couterAuditerContainer {
  border-radius: 4px;
  border: 1px solid var(--grey-light, #d9dde8);
  padding: 32px;
  margin-top: 50px;
  .couterAuditertab {
    margin-top: -70px;
  }
  .searchFilterContainer {
    gap: 15px;
    justify-content: end;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 768px) {
    padding: 0px;
    border: 0px;
    margin-top: 0px;
    .couterAuditertab {
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .searchFilterContainer {
      justify-content: space-between;
      margin-bottom: 20px;
    }
  }
}

.countingTaskContainer {
  gap: 20px;
}
