.tag_content_container {
  height: auto;
}
.tagContainerInner {
  border-radius: 4px;
  border: 1px solid var(--grey-light, #d9dde8);
  background: #fff;
  padding: 24px;
  margin: 24px 0px !important;
  .tagContainerTop {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #f0f1f8;
  }
  .tagName {
    h3 {
      color: #a4a4a4;
      font-weight: 600;
      margin: 0px;
      b {
        color: #494949;
        font-weight: 600;
      }
    }
    ul {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
      gap: 15px;
      justify-content: start;
      align-items: center;
      margin-left: 50px;
      li {
        font-size: 14px;
        font-weight: 500;
        color: #a4a4a4;
        line-height: normal;
        position: relative;
        padding-right: 15px;
        .labelName {
          font-size: 14px;
          font-weight: 600;
          color: #494949;
          margin-right: 5px;
        }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          background: #b5bcd3;
          width: 1px;
          height: 10px;
        }
        &:last-child {
          padding-right: 0px;
          &::after {
            display: none;
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      ul {
        margin-top: 10px;
        margin-left: 0px;
      }
    }
  }
  .listContainer {
    justify-content: start;
    ul {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
      gap: 24px;
      justify-content: start;
      align-items: center;
      width: 100%;
      li {
        color: #494949;
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        position: relative;
        padding-right: 24px;
        max-width: 40%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        .tooltip_div {
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: block;
        }
        .labelName {
          font-size: 14px;
          font-weight: 500;
          color: #a4a4a4;
          display: block;
          margin-bottom: 5px;
        }
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          background: #b5bcd3;
          width: 1px;
          height: 21px;
        }
        &:last-child {
          padding-right: 0px;
          &::after {
            display: none;
          }
        }
      }
      @media screen and (max-width: 768px) {
        gap: 15px;
        justify-content: start;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 0px;
        li {
          padding-right: 15px;
        }
      }
    }
  }
  .tagContainerBottom {
    align-items: center;
    .listContainer {
      justify-content: start;
    }
    .fieldContainer {
      gap: 15px;
    }
    .btnContainer {
      justify-content: end;
      @media screen and (max-width: 768px) {
        margin-top: 10px;
      }
    }
  }

  .tagContainer_heading h4 {
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 16px;
    color: #494949;
  }
  .tagContainer_details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    ul {
      display: flex;
      align-items: center;
      justify-content: start;
      list-style: none;
      gap: 16px;
      margin: 0px;
      padding: 0px;
      li {
        border: 1px solid #d9dde8;
        border-radius: 4px;
        padding: 14px 16px;
        width: 180px;
        color: #494949;
        b {
          display: block;
          margin-top: 5px;
        }
        &:first-child {
          background: var(--primary-color);
          color: var(--White);
        }
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      ul {
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 20px;
        width: 100%;
        li {
          padding: 8px;
          width: calc(50% - 5px);
          &:first-child {
            width: 100%;
          }
        }
      }
    }
  }
}

.reconciliationTagBox {
  padding: 24px;
  background: #f0f1f8;
  margin-bottom: 16px !important;
  .forceAuditLabel {
    padding-left: 0px;
    margin: 0px;
    margin-left: -10px;
    margin-bottom: 16px;
    width: 100%;
    .MuiFormControlLabel-label {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
.reconciliationTagBox_form {
  align-items: center;
  .fieldContainer {
    gap: 15px;
  }
  .btnContainer {
    justify-content: end;
    @media screen and (max-width: 768px) {
      margin-top: 10px;
      justify-content: center;
    }
  }
}
