.page_title {
  margin-top: 10px;
}

.outerBox {
  display: flex;
  width: 100%;
  padding: 24px;
  justify-content: flex-start;
  gap: 15px;
  align-items: center;
  border: 1px solid var(--Outline-Grey, #e0e0e0);
  flex-wrap: wrap;
  margin-top: 30px;
  opacity: 0.8;
  .settingIcon {
    align-self: flex-start;
  }
  .boxContent {
    @media screen and (min-width: 768px) {
      width: 50%;
    }
    h3 {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 0px;
      margin-bottom: 8px;
      color: #292a2b;
    }
    p {
      margin: 0;
    }
  }
  .arrowIcon {
    margin-left: auto;
  }
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.inventoryDropMenu {
  ul {
    width: 100%;
    padding: 0px;
    border-radius: 4px;
    background: #fff;
    // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    li {
      color: #494949;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding: 15px;
      // border-bottom: 0.4px solid rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.AddInventory_Box {
  background: #f0f1f8;
}
