.full_height_section {
  height: 100%;
  width: 100%;
  align-items: center;
  .login_left {
    height: 100%;
    display: flex;
    align-items: end;
    padding: 32px;
    padding-bottom: 7rem;
    background-image: url(../../../public/assets/login-welcome-frame.png);
    background-size: cover;
    background-position: bottom right;
    background-repeat: no-repeat;
    .login_left_content {
      h3 {
        color: var(--White, #fff);
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 31px; /* 86.111% */
      }
      p {
        color: var(--White, #fff);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
      }
    }
  }
  .login_right {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .login_right_content {
      width: 60%;
      .fieldWrapper {
        margin-bottom: 24px !important;
      }
      h3 {
        color: var(--primary-color, #115acb);
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 24px;
        margin-top: 0px;
        @media screen and (max-width: 1460px) {
          margin-bottom: 10px;
        }
      }
      .forgetPasswordContainer {
        margin-top: 12px;
        .forgetPassword {
          color: #a1a1a1;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration: underline;
          -webkit-text-decoration: underline;
        }
      }
      .registerLinkContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 24px 0px;
        color: var(--grey, #424750);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        gap: 10px;
        .registerLink {
          color: var(--primary-color, #115acb);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-decoration: none;
          -webkit-text-decoration: none;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    height: auto;
    align-items: start;
    .login_left,
    .login_right {
      height: auto;
      padding: 20px;
      background-position: top right;
    }
    .login_right {
      .login_right_content {
        width: 100%;
        padding: 20px;
      }
    }
  }
}
