.nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-right: 0.5px solid #dadadb;
  background: #fff;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 30px;
    margin: 0;
    list-style: none;
    height: 100%;
    @media screen and (max-width: 768px) {
      padding: 30px 20px;
    }
    li {
      margin: 5px 0px;
      a {
        font-family: "Montserrat";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        text-transform: capitalize;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #494949;
        font-weight: 700;
        font-size: 14px;
        padding: 10px 15px;
        border-radius: var(--Spacing-Spacing-8px, 8px);
        transition: color 0.3s, border-left 0.3s;
        // border-left: 5px solid transparent;
        width: 100%;
        @media screen and (max-width: 768px) {
          padding: 15px;
        }
        svg {
          width: 24px !important;
          height: 24px !important;
        }
        &:hover {
          background: #f0f1f8;
          // border-left: 5px solid #414ed5;
        }
      }
      .active {
        // Adding active class styles
        background: #f0f1f8;
      }
      &:last-child {
        margin-top: auto;
        @media screen and (max-width: 768px) {
          margin-top: auto;
          margin-bottom: 50px;
        }
      }
    }
    &.Mat2rixPage {
      li {
        &:last-child {
          margin-top: 5px;
          @media screen and (max-width: 768px) {
            margin-top: auto;
            margin-bottom: 50px;
          }
        }
      }
    }

    // li:not(:last-child) {
    //   margin-right: 20px; // Adjust space between menu items
    // }
  }
  @media screen and (max-width: 768px) {
    position: absolute;
  }
}
