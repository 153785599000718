.tagContainerInner {
  border-radius: 4px;
  border: 1px solid var(--grey-light, #d9dde8);
  background: #fff;
  margin-bottom: 16px !important;
  .tagContainerTop {
    padding: 10px 24px;
    background: #fafafd;
    @media screen and (max-width: 768px) {
      padding: 10px 14px;
      position: relative;
      padding-right: 20px;
    }
  }
  .tagName {
    align-items: center;
    h3 {
      color: #a4a4a4;
      font-weight: 600;
      margin: 0px;
      font-size: 16px;
      b {
        color: #494949;
        font-weight: 600;
      }
    }
    ul {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
      gap: 15px;
      justify-content: start;
      align-items: center;
      li {
        font-size: 16px;
        font-weight: 500;
        color: #a4a4a4;
        line-height: normal;
        position: relative;
        padding-right: 15px;
        .labelName {
          font-size: 16px;
          font-weight: 600;
          color: #494949;
          margin-right: 5px;
        }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          background: #b5bcd3;
          width: 1px;
          height: 10px;
        }
        &:last-child {
          padding-right: 0px;
          &::after {
            display: none;
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      ul {
        margin-top: 10px;
        margin-left: 0px;
      }
    }
  }
  .btnContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: end;
    .numberoftag {
      border-radius: 4px;
      border: 1px solid #8590ac;
      background: #fff;
      padding: 10px 30px;
      width: auto;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: var(--grey);
      text-align: center;
    }
    @media screen and (max-width: 768px) {
      justify-content: start;
      margin-top: 10px;
      .numberoftag {
        padding: 8px 12px;
      }
      .numberoftag + button {
        margin-left: auto;
        position: absolute;
        top: 15px;
        right: 15px;
      }
    }
  }
  .listContainer {
    justify-content: end;
    ul {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
      gap: 24px;
      justify-content: start;
      align-items: center;
      width: calc(100% - 50px);
      li {
        color: #494949;
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        position: relative;
        padding-right: 24px;
        max-width: 40%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        .tooltip_div {
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: block;
        }
        .labelName {
          font-size: 14px;
          font-weight: 500;
          color: #a4a4a4;
          display: block;
          margin-bottom: 5px;
        }
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          background: #b5bcd3;
          width: 1px;
          height: 21px;
        }
        &:last-child {
          padding-right: 0px;
          &::after {
            display: none;
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      .listContainer {
        ul {
          gap: 15px;
          justify-content: start;
          flex-wrap: wrap;
          align-items: center;
          margin-top: 15px;
          flex-basis: calc(100% - 30px);
          li {
            padding-right: 15px;
          }
        }
      }
    }
  }
  .tagContainerBottom {
    padding: 24px;
    align-items: center;
    .listContainer {
      justify-content: space-between;
    }
    @media screen and (max-width: 768px) {
      padding: 10px 14px;
      .listContainer {
        padding-right: 10px;
        align-items: center;
        ul {
          gap: 15px;
          justify-content: start;
          flex-wrap: wrap;
          align-items: center;
          margin-top: 15px;
          flex-basis: calc(100% - 30px);
          li {
            padding-right: 15px;
          }
        }
      }
    }
  }
}
.round_btn {
  background: #f0f1f8;
  border: 1px solid #40475b !important;
  width: 30px !important;
  height: 30px !important;
  padding: 0px !important;
}
.forceAudit {
  padding: 10px 24px;
  .forceAuditLabel {
    padding-left: 16px;
    margin: 0px;
    background: #f0f1f8;
    width: 100%;
    .MuiFormControlLabel-label {
      font-size: 16px;
      font-weight: 600;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 10px 14px;
  }
}

.inventoryDropMenu {
  ul {
    width: 100%;
    padding: 0px;
    border-radius: 4px;
    background: #fff;
    // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    li {
      // color: #494949;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding: 15px;
      // border-bottom: 0.4px solid rgba(0, 0, 0, 0.3);
      width: 100%;
      white-space: normal;
      .menuName {
        width: calc(100% - 25px);
      }
    }
  }
}
